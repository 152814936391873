.login_screen {
  background-image: linear-gradient(160deg, #193a21 0%, #008a4b 100%);
  height: 100%;
}

.paragrafo {
  color: #fff;
  width: 45%;
  min-width: 250px;
  display: inline-block;
  vertical-align: text-top;
}

.card_background {
  background-color: #fff;
  width: 80%;
  border-radius: 10px !important;
  margin-top: 30%;
}

.login-form {
  max-width: 400px;
  display: block;
  margin: auto;
}

.login-form-forgot {
  float: right;
  border-radius: 10px !important;
}

.ant-col-rtl .login-form-forgot {
  float: left;
  border-radius: 10px !important;
}

.login-form-button {
  width: 80%;
}

@media screen and (min-width: 960px) {
  .card_background {
    width: 40%;
  }
  .login-form-button {
    width: 50%;
  }
}

@media screen and (min-width: 1480px) {
  .login-form-button {
    width: 30%;
  }
}
