.custom-cell-button {
  border: 0;
  display: flex;
  width: 100%;
  padding: 29px;
  background: transparent;
  cursor: pointer;
  overflow: hidden;
}

.second-row {
  border: 0;
  display: flex;
  width: 100%;
  padding: 19px;
  background: transparent;
  cursor: pointer;
  color: #a6aeba !important;
}

.ant-tag {
  display: inline-flex;
  align-items: center;
  height: 24px;
  padding: 0 8px;
  font-size: 12px !important;
  font-weight: 600 !important;
  margin: 0;
  margin-right: 0;
  gap: 4px;
  border-radius: 999px !important;
  border: 0;
  width: max-content;
}

.ant-tag-success {
  color: #008738 !important;
  background: rgba(11, 167, 73, 0.1) !important;
  border: 0 !important;
}

.ant-tag-error {
  color: #e6171e !important;
  background: rgba(247, 62, 65, 0.1) !important;
  border: 0 !important;
}

.ant-tag-default {
  color: rgba(48, 55, 66, 1) !important;
  background: rgba(118, 127, 141, 0.1) !important;
  border: 0 !important;
}

