.pending-info {
  font-family: "Sharon Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 145%;
  /* identical to box height, or 22px */

  display: flex;
  align-items: center;
  letter-spacing: -0.01em;

  /* ☀︎ Content/Neutral High */

  color: #303742;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.forms-filters > .search-box-form {
  max-width: 326px;
}

.forms-filters > .searched-box {
  max-width: 326px;
}

.forms-filters > .custom-select {
  max-width: 226px;
}

.card-content-gap-1 > .body-regular-sm {
  color: rgba(118, 127, 141, 1);
}

.forms-filters {
  display: flex;
  min-width: 55%;
  justify-content: flex-end;
  gap: 20px;
  height: 46px;
}

.header-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.header-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-title {
  color: #303742;
  font-size: 17px;
  letter-spacing: -0.02em;
}

.header-description {
  color: #767f8d;
  font-size: 12px;
  letter-spacing: -0.01em;
  line-height: 1;
}

.ant-drawer-close {
  order: 3;
}

.detail-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.detail-title {
  font-family: "Sharon Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 145%;
  letter-spacing: -0.01em;
  color: #767f8d;
}

.detail-description {
  font-family: "Sharon Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 145%;
  text-align: right;
  letter-spacing: -0.01em;
  color: #303742;
  margin-top: 2px;
}

.defect-container {
  display: grid;
  grid-template-areas: "info image";
}

.defect-divider {
  margin-bottom: 8px;
}

.defect-info {
  grid-area: "info";
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.defect-images {
  grid-area: "image";
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
}

.defect-row {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.drawer-content {
  height: 70vh;
  display: flex;
  flex-direction: column;
  scroll-behavior: smooth;
}

.tag-soft-machine {
  justify-self: flex-end;
  margin-top: 16px !important;
}

.judge-defects {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 0;
}

.software-checkbox {
  margin-top: 24px !important;
  padding-bottom: 24px !important;
  font-family: "Sharon Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 145%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
}

.heading-bold-lg {
  color: #303742;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -0.02em;
}

.pending-tag {
  color: black !important;
}

.ant-pagination > .ant-table-pagination {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  gap: 16px;
}

.ant-select-selection-item {
  color: #303742;
}

.alert-container {
  margin-bottom: 24px !important;
}

.empty-warn {
  font-family: "Sharon Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: -0.02em;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #303742;
}

.feedback-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  margin-top: 200px;
}

.feedback-text {
  font-family: "Sharon Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 145%;
  /* or 22px */

  text-align: center;
  letter-spacing: -0.01em;
}

.ant-pagination .ant-table-pagination {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
}

.ant-pagination ul,
.ant-pagination ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ant-table-pagination.ant-pagination {
  margin: 1.5rem;
  position: relative;
}

.ant-pagination-options {
  display: inline-flex;
  position: absolute;
  left: 0;
}

.ant-pagination-options::before {
  content: "Linhas por página";
  color: #767f8d;
  font-size: 13px;
  letter-spacing: -0.01em;
  margin-right: 0.5rem;
}

.ant-pagination-total-text {
  position: absolute;
  inset: auto;
  display: inline-block;
  font-weight: 400;
  font-size: 13px;
  color: #767f8d;
  line-height: 145%;
  letter-spacing: -0.01em;
  height: 2.25rem !important;
  text-align: center;
  margin-right: 0.5rem;
  line-height: 1.875rem;
  vertical-align: middle;
}

.ant-pagination-prev,
.ant-pagination-next {
  height: 2.25rem !important;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  background-color: rgba(118, 127, 141, 0.1) !important;
  border: 0;
  border-radius: 0.5rem !important;
}

.ant-pagination-item {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 2.25rem !important;
  margin-right: 0.5rem !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 1.875rem !important;
  text-align: center !important;
  vertical-align: middle !important;
  list-style: none;
  background-color: rgba(118, 127, 141, 0.1) !important;
  border: 0;
  border-radius: 0.5rem !important;
  outline: 0;
  cursor: pointer !important;
  user-select: none;
}

.ant-pagination-item > a {
  color: #303742 !important;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: -0.01em;
}

.ant-pagination-item > a:hover {
  color: #303742 !important;
}

.ant-pagination-item-active {
  border: 1.5px solid #303742 !important;
}

.ant-pagination-item-active > a {
  color: #303742 !important;
}

.ant-pagination-item-active > a:hover > a {
  color: #303742 !important;
}

.ant-pagination-prev {
  margin-left: auto;
}

.ant-select.ant-pagination-options-size-changer.ant-select-single.ant-select-show-arrow
  > .ant-select-selector {
  border: 0;
  width: 72px !important;
  background-color: rgba(118, 127, 141, 0.1) !important;
  border-radius: 8px;
  background: var(--Content-Neutral-Overlay, rgba(118, 127, 141, 0.10));
}

.ant-select.ant-pagination-options-size-changer.ant-select-single.ant-select-show-arrow
  > .ant-select-selector
  > .ant-select-selection-item {
  display: flex !important;
  align-items: center !important;
  font-weight: 600;
  font-size: 13px;
  letter-spacing: -0.01em;
  color: #303742 !important;
}

.ant-select.ant-pagination-options-size-changer.ant-select-single.ant-select-show-arrow
  > .ant-select-arrow
  .anticon
  > svg {
  color: rgba(0, 0, 0, 1) !important;
}

.ant-pagination-item a {
  display: block;
  padding: 0 13px !important;
  color: rgba(0, 0, 0, 0.65);
  transition: none;
}

.ant-table-thead > tr > th {
  background-color: #fff !important;
  color: #767f8d !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  padding: 1.25rem 1rem;
  border-bottom: 1px solid rgba(118, 127, 141, 0.1) !important;
}

.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  width: 0;
}

.ant-table-tbody tr td {
  color: #303742;
  font-size: 15px;
  letter-spacing: 0.04em;
  padding: 1rem;
  border-bottom: 1px solid rgba(118, 127, 141, 0.1);
}

.ant-table-column-sorters {
  justify-content: flex-start;
  white-space: nowrap;
}

.ant-table-column-title {
  flex: initial;
}

.ant-modal-title {
  color: #303742;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.02em;
  line-height: 1.2;
}

.ant-modal-content {
  border-radius: 12px;
}

.ant-modal-header {
  border-radius: 12px 12px 0 0;
}

.ant-pagination-item-active:focus-visible,
.ant-pagination-item-active:hover {
  border-color: #5a8f5c !important;
}

.ant-pagination-item-active {
  font-weight: 500;
  background: #fff;
  border-color: #3c8141 !important;
}
.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a {
  color: #3c8141 !important;
}

.ant-drawer-header {
  border-bottom: 1px solid #f0f0f000 !important;
}

.ant-tag {
  display: inline-flex;
  align-items: center;
  height: 24px;
  padding: 0 8px;
  font-size: 12px !important;
  font-weight: 600 !important;
  margin: 0 !important;
  margin-right: 0 !important;
  gap: 4px;
  border-radius: 999px !important;
  border: 0;
  width: max-content;
}
