.custom-select {
  margin-bottom: 1.4rem;
  width: 100%;
  position: relative;
}

.select-float-label {
  position: absolute;
  top: -9px;
  left: 18px;
  z-index: 1000;
  background-color: white;
  padding: 0 4px;
  color: #a6aeba;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: -0.01em;
}

.select-float-label-error {
  color: #d95e00;
}

.ant-input-affix-wrapper > input.ant-input {
  padding: 11px !important;
}
/* ant-input:focus {
    border-color: #f2f;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(87,168,233, .2);
    box-shadow: 0 0 0 2px rgba(87,168,233, .2);
} */
