.card-container {
  background-color: white;
  border-radius: 12px;
  overflow: hidden;
}

.card-container.bordered {
  border: 1.5px solid rgba(48, 55, 66, 0.2);
}

.card-header {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 24px;
  border-bottom: 1px solid rgba(118, 127, 141, 0.1);
}

.card-header p {
  line-height: 1.2;
}

.card-header.small-padding {
  padding: 16px;
}

.card-header-title {
  color: #303742;
  font-weight: 600;
  font-size: 17px;
  letter-spacing: -0.02em;
}

.card-content {
  display: flex;
  width: 100%;
  padding: 24px;
  gap: 24px;
}

.card-content.column {
  flex-direction: column;
}

.card-content.row {
  flex-direction: row;
}

.card-content.small-padding {
  padding: 16px;
}

.card-content.no-padding {
  padding: 0px;
}
