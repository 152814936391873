.ant-input {
  border-radius: 4px !important;
}

.ant-input[disabled] {
  color: #303742 !important;
  opacity: 0.38 !important;
}

.ant-input-status-error {
  border: 2px solid #f38428 !important;
}

.ant-input-affix-wrapper {
  border-radius: 8px !important;
}
