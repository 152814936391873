.search-box {
  display: flex;
  gap: 8px;
}

.search-box-form {
  width: 100%;
}

.search-box-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  border-radius: 8px;
  background-color: rgba(118, 127, 141, 0.1);
  border: 0;
}

.search-box-button.search-box-button-sm {
  width: 44px;
  height: 46px;
  cursor: pointer;
}

.searched-box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  height: 56px;
  width: 100%;
  border-radius: 0.25rem;
  border: 1px solid #a6aeba;
  padding: 0 1rem;
  color: #303742;
}

.searched-box.searched-box-sm {
  height: 44px;
}

.searched-box input {
  border: 0;
  padding: 0;
}

.searched-box > .custom-select > .ant-input-affix-wrapper-disabled {
  border: none !important;
  height: 40px;
}

.ant-input-affix-wrapper {
  border: 1px solid transparent;
  box-shadow: 0 0 0 0px #ffffff00;
  position: relative;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #a6aeba;
  border-right-width: 1px;
  z-index: 1;
}

.ant-input-affix-wrapper-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: transparent !important;
  cursor: not-allowed;
  opacity: 1;
}
